@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Montserrat:wght@700&family=Raleway:wght@700&display=swap');

.sidebar {
  width: 180px;
  background-color: #080350;
  color: #ecf0f1;
  display: flex;
  flex-direction: column;
  padding: 0;
  transition: width 0.3s ease;
  height: 100vh;
}

.sidebar.collapsed {
  width: 60px;
}

.sidebar__logo {
  text-align: center;
  padding: 0.375rem 0.1875rem;
  margin-bottom: 0.375rem;
}

.sidebar__logo img {
  width: 100%;
  max-width: 160px;
  height: auto;
  padding-top: 0;
}

.sidebar.collapsed .sidebar__logo img {
  max-width: 40px;
}

.sidebar__nav {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.sidebar__nav-button {
  background: none;
  border: none;
  color: #ecf0f1;
  padding: 0.75rem 1.125rem;
  text-align: left;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 600;
  font-family: 'Noto Sans', 'Century Gothic', CenturyGothic, AppleGothic, sans-serif;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sidebar.collapsed .sidebar__nav-button {
  justify-content: center;
  padding: 0.75rem 0;
}

.sidebar__nav-button:nth-child(1) {
  font-family: 'Poppins', sans-serif;
}

.sidebar__nav-button:nth-child(2) {
  font-family: 'Montserrat', sans-serif;
}

.sidebar__nav-button:nth-child(3) {
  font-family: 'Raleway', sans-serif;
}

.sidebar__nav-button:hover {
  background-color: #1e90ff;
}

.sidebar__nav-button.active {
  background-color: #1e90ff;
  font-weight: 700;
}

.sidebar__nav-icon {
  font-size: 1.2rem;
}

.sidebar.collapsed .sidebar__nav-button span {
  display: none;
}

.sidebar__toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  margin-top: auto;
}

.sidebar__toggle-icon {
  font-size: 1rem;
  color: #ecf0f1;
}

.sidebar__toggle-icon:first-child {
  margin-right: -0.5rem;
}

.sidebar.collapsed .sidebar__toggle {
  transform: rotate(180deg);
}

/* Remove unused styles */
.sidebar__user-info,
.sidebar__welcome-message,
.sidebar__logout-button,
.sidebar__view-container {
  display: none;
}
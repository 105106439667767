.reports-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.reports-container h2 {
  margin-bottom: 1rem;
}

.reports-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.report-button {
  padding: 1rem;
  font-size: 1rem;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.report-button:hover {
  background-color: #e0e0e0;
}

.report-display {
  flex-grow: 1;
  width: 100%;
  height: calc(100vh - 150px); /* Adjust this value as needed */
  padding: 1rem;
  overflow: auto;
}

.no-report-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.2rem;
  color: #666;
}

.map-report {
  width: 100%;
  height: 100%;
}

.map-report h2 {
  margin-bottom: 1rem;
}
:root {
  --dark-blue: #080350;
  --blue: #0C32A4;
  --light-blue: #1E90FF;
  --very-light-blue: #D7ECFD;
  --yellow: #FFFF33;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(8, 3, 80, 0.7); /* Increased opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 1200px;
  height: 95vh; /* Changed from max-height to fixed height */
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid white;
}

.modal-header {
  background: linear-gradient(to bottom, var(--dark-blue), var(--blue));
  color: white;
  padding: 15px 20px; /* Increased padding */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.3rem;
  color: white; /* Changed from color: white !important; */
}

.close-button {
  background: none;
  border: none;
  font-size: 1.3rem;
  cursor: pointer;
  color: white;
}

.form-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.form-column {
  flex: 1;
  min-width: 300px; /* Increased from 200px */
}

.bio-short-inputs-container {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 0;
  align-items: flex-start; /* Align items to the top */
}

.bio-container {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.short-inputs-grid {
  flex: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: -10px; /* Add negative margin to reduce space above insurance/workers comp */
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-group.full-width {
  width: 100%;
  margin-top: 10px;
  padding: 0 20px; /* Add some padding on the sides */
}

.form-group label {
  margin-bottom: 3px;
  font-size: 0.8rem;
  color: var(--dark-blue);
}

.form-group input,
.form-group select,
.form-group textarea,
.reset-password-button {
  padding: 6px;
  border: 1px solid var(--light-blue);
  border-radius: 4px;
  font-size: 0.8rem;
  height: 32px; /* Set a fixed height for all inputs and the button */
}

.form-group textarea {
  height: 100px; /* Reduced from 120px */
  min-height: 100px; /* Reduced from 120px */
  resize: vertical;
}

.short-input {
  display: flex;
  flex-direction: column;
}

.short-input label {
  font-size: 0.8rem; /* Changed from 0.7rem to match other labels */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px; /* Changed from 2px to match other labels */
  color: var(--dark-blue); /* Added to match other labels */
}

.short-input input,
.short-input select {
  width: 100%;
  padding: 6px; /* Changed from 3px 5px to match other inputs */
  font-size: 0.8rem; /* Changed from 0.7rem to match other inputs */
  height: 32px; /* Changed from 24px to match other inputs */
  box-sizing: border-box;
  border: 1px solid var(--light-blue); /* Added to match other inputs */
  border-radius: 4px; /* Added to match other inputs */
}

.services-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 5px;
  min-height: 100px; /* Give more vertical space */
  padding: 10px;
  background-color: #fafafa;
  border-radius: 4px;
}

.service-tag {
  background-color: var(--very-light-blue);
  color: var(--dark-blue);
  padding: 4px 12px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  font-size: 0.9em;
}

.service-tag svg {
  margin-left: 8px;
  cursor: pointer;
  color: var(--blue);
}

.add-service {
  margin-top: 8px;
  width: 100%;
  padding: 8px;
}

.add-service option {
  display: block; /* Changed from 'none' to 'block' */
  background-color: white;
  color: var(--dark-blue);
  padding: 5px;
}

.add-service:hover {
  background-color: #C0FFC0;
}

.add-service::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.add-service:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--dark-blue);
}

/* Ensure the text is centered in all browsers */
.add-service {
  text-align-last: center;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  background-color: #f5f5f5;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  gap: 12px;
  border-top: 1px solid #ddd; /* Add a separator line */
  flex-shrink: 0; /* Prevent the button group from shrinking */
}

.cancel-button,
.save-button,
.reset-password-button {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cancel-button {
  background: linear-gradient(to bottom, #d0d0d0, #b0b0b0); /* Darker grey */
  color: var(--dark-blue);
}

.cancel-button:hover {
  background: linear-gradient(to bottom, #c0c0c0, #a0a0a0); /* Darker grey on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.save-button,
.reset-password-button {
  background: linear-gradient(to bottom, var(--light-blue), var(--blue));
  color: white;
}

.save-button:hover,
.reset-password-button:hover {
  background: linear-gradient(to bottom, var(--blue), var(--dark-blue));
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.reset-password-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  background: linear-gradient(to bottom, var(--light-blue), var(--blue));
  color: white !important; /* Added !important to ensure it overrides other styles */
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reset-password-button:hover {
  background: linear-gradient(to bottom, var(--blue), var(--dark-blue));
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.reset-password-button svg {
  font-size: 1.1em;
  color: white;
}

.reset-password-button span {
  color: white !important; /* Added to ensure the text is white */
}

/* Remove or comment out this conflicting rule if it exists */
/*
.reset-password-button {
  background-color: var(--yellow);
  color: var(--dark-blue);
}
*/

.email-input-container {
  display: flex;
  align-items: center;
}

.email-input-container input {
  flex-grow: 1;
}

.password-reset-icon {
  color: var(--yellow);
  cursor: pointer;
  margin-left: 8px;
  font-size: 1em;
  transition: color 0.3s ease;
}

.password-reset-icon:hover {
  color: #FFD700;
}

.password-group {
  margin-top: 10px;
}

.password-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.password-input-container input {
  flex-grow: 1;
  background-color: #f0f0f0;
  color: #666;
  cursor: not-allowed;
}

.reset-password-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: var(--yellow);
  color: var(--dark-blue);
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  width: 100%; /* Make the button full width */
}

.reset-password-button:hover {
  background-color: #FFD700;
}

.reset-password-button svg {
  font-size: 1em;
}

@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    max-height: 98vh; /* Increased for mobile */
  }

  .bio-short-inputs-container {
    flex-direction: column;
  }

  .short-inputs-grid {
    grid-template-columns: 1fr;
  }
}

/* Add these new styles */
.form-layout {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.form-main {
  flex: 2;
}

.form-services {
  flex: 1;
  border-left: 1px solid var(--light-blue);
  padding-left: 20px;
}

/* Add these new styles */
.view-toggle {
  display: flex;
  gap: 10px;
  margin-right: auto;
  margin-left: 20px;
}

.view-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  transition: color 0.3s ease;
}

.view-button:hover {
  color: white;
}

.view-button.active {
  color: white;
  font-weight: 500;
}

.services-view {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%; /* Add this */
  padding-bottom: 20px; /* Add some bottom padding for content */
}

.selected-services-summary {
  padding: 15px;
  background: #f8f9fa;
  border-radius: 6px;
}

.selected-services-summary h3 {
  margin: 0 0 10px 0;
  font-size: 1rem;
  color: var(--dark-blue);
}

.service-categories-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.category-section {
  border: 1px solid var(--light-blue);
  border-radius: 6px;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  cursor: pointer;
  background: #f8f9fa;
}

.category-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  flex: 1;
}

.selection-status {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: 12px;
}

.status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.status-indicator.none {
  background-color: #e0e0e0;
}

.status-indicator.partial {
  background-color: var(--light-blue);
}

.status-indicator.all {
  background-color: #4caf50;
}

.status-count {
  font-size: 0.8rem;
  color: #666;
}

.select-all-button {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: all 0.2s ease;
}

.select-all-button.none {
  background-color: var(--very-light-blue);
  color: var(--dark-blue);
}

.select-all-button.partial {
  background-color: var(--light-blue);
  color: white;
}

.select-all-button.all {
  background-color: #4caf50;
  color: white;
}

.select-all-button:hover {
  opacity: 0.9;
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.category-services {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 8px;
}

.service-item {
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.service-item:hover {
  background: var(--very-light-blue);
}

.service-item.selected {
  background: var(--light-blue);
  color: white;
}

.service-tag.unavailable {
  background-color: #ffebee;
  color: #d32f2f;
  border: 1px solid #ffcdd2;
}

.service-tag.unavailable svg {
  color: #d32f2f;
}

.summary-header {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.summary-header h3 {
  display: flex;
  align-items: center;
  gap: 8px;
}

.selected-services-tags {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.selected-services-summary {
  padding: 12px;
  background: #f8f9fa;
  border-radius: 6px;
  border: 1px solid var(--light-blue);
}

.selected-services-summary h3 {
  margin: 0;
  font-size: 1rem;
  color: var(--dark-blue);
}

/* Add this new class */
.modal-body {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  padding: 20px;
}

.services-search {
  position: relative;
  margin-bottom: 10px;
}

.services-search input {
  width: 100%;
  padding: 10px;
  padding-left: 35px;
  border: 1px solid var(--light-blue);
  border-radius: 6px;
  font-size: 0.9rem;
  background: white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%231E90FF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'/%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'/%3E%3C/svg%3E") no-repeat 10px center;
  background-size: 16px;
}

.services-search input:focus {
  outline: none;
  border-color: var(--blue);
  box-shadow: 0 0 0 2px var(--very-light-blue);
}

.services-search input::placeholder {
  color: #999;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.login-box {
  background-color: #080350;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 500px;
  text-align: center;
}

.login-logo {
  width: 80%;
  max-width: 400px;
  margin-bottom: 20px;
}

.login-box h1 {
  color: white;
  margin-bottom: 10px;
  font-size: 28px;
}

.stylized-text {
  color: #e0e0e0;
  font-size: 18px;
  margin-bottom: 30px;
  font-style: italic;
}

.login-box form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-box input {
  width: 90%;
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
}

.sign-in-button {
  width: 90%;
  padding: 10px;
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-button:hover {
  opacity: 0.9;
}

.sign-in-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.button-logo {
  height: 48px; /* Doubled from 24px */
  margin-left: 5px; /* Reduced from 10px */
  vertical-align: middle; /* Ensures vertical alignment with text */
}

.error {
  color: #ff4d4d;
  margin-bottom: 20px;
  font-weight: bold;
}
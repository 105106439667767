.support {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 800px;
  max-width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}

.support h2 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
  padding: 15px 20px;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
  background: linear-gradient(to bottom, var(--dark-blue), var(--blue));
}

.support-icon {
  font-size: 1.5em;
  color: #ffffff;
}

.support-content {
  padding: 20px;
}

.mode-toggle {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  width: 60%;
}

.mode-toggle button {
  padding: 8px 16px;
  background: #e0e0e0;
  color: #666;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  font-weight: bold;
  flex: 1;
  white-space: nowrap;
}

.mode-toggle button.active {
  background: linear-gradient(to bottom, var(--light-blue), var(--blue));
  color: white;
}

.mode-toggle button:hover:not(.active) {
  background: #d0d0d0;
}

.mode-toggle button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.mode-toggle button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.support-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--dark-blue);
  padding: 5px 0;
}

.form-group input,
.form-group select {
  padding: 6px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  height: 32px;
}

.form-group textarea {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  height: 120px;
  resize: vertical;
}

.file-input-label {
  display: inline-block;
  cursor: pointer;
  background-color: #f0f0f0;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
}

.file-input-label:hover {
  background-color: #e0e0e0;
}

.file-input {
  display: none;
}

.file-list {
  margin-top: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
}

.file-list p {
  margin: 0 0 5px 0;
  font-weight: bold;
}

.file-list ul {
  list-style-type: none;
  padding: 0;
}

.file-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.remove-file-btn {
  background: none;
  border: none;
  color: #ff4d4d;
  cursor: pointer;
  padding: 2px;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.remove-file-btn:hover {
  color: #ff0000;
}

.submit-button {
  align-self: flex-start;
  padding: 8px 16px;
  background: linear-gradient(to bottom, var(--light-blue), var(--blue));
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background 0.3s ease;
  margin-top: 5px;
}

.submit-button:hover {
  background: linear-gradient(to bottom, var(--blue), var(--dark-blue));
}

.submit-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.submit-message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  font-weight: bold;
}

.submit-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.submit-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Add these variables if they're not already defined in a parent CSS file */
:root {
  --dark-blue: #080350;
  --blue: #0C32A4;
  --light-blue: #1E90FF;
}

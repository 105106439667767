/* Add these color variables at the top of the file */
:root {
  --dark-blue: #080350;
  --blue: #0C32A4;
  --light-blue: #1E90FF;
  --very-light-blue: #D7ECFD;
  --yellow: #FFF33;
  --table-header-gradient: linear-gradient(to bottom, #080350, #0C32A4);
  --table-row-hover: #f0f4ff;
  --table-border: #e0e0e0;
}

.contractors {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 10px; /* Add some padding to the entire component */
}

.contractors h2 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 0 5px 0; /* Reduced bottom margin */
  color: #080350;
  font-size: 1.2rem; /* Slightly smaller */
  font-weight: bold;
}

.contractors-icon {
  font-size: 1.2em;
  color: var(--dark-blue);
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.search-bar {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  background-color: #fff;
  border: 1px solid #080350;
  border-radius: 4px;
  width: 100%;
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 50%; /* Adjust this value to control the width of the search input */
  border: 1px solid #ccc; /* Add a subtle border */
  border-radius: 4px;
  padding: 2px 8px;
}

.search-input-wrapper:hover,
.search-input-wrapper:focus-within {
  border-color: #080350; /* Darken border on hover/focus */
}

.search-bar input {
  flex-grow: 1;
  margin-left: 5px;
  padding: 5px;
  border: none;
  font-size: 0.8em;
  color: #080350;
  min-width: 0;
  width: 100%;
}

.search-bar input::placeholder {
  color: #999;
}

.search-bar svg {
  color: #080350;
  font-size: 0.9em;
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.service-filter {
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;
}

.service-filter select {
  margin-left: 5px;
  border: 1px solid #ccc; /* Add a subtle border */
  background-color: transparent;
  font-size: 0.8em;
  color: #080350;
  cursor: pointer;
  padding: 4px 20px 4px 8px; /* Adjust padding */
  border-radius: 4px; /* Add border-radius */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23080350" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 4px center;
  background-size: 16px;
}

.service-filter select:hover,
.service-filter select:focus {
  border-color: #080350; /* Darken border on hover/focus */
  outline: none;
}

.service-filter svg {
  color: #080350;
  font-size: 0.9em;
}

.active-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 3px; /* Reduced from 6px */
  margin-top: 2px; /* Reduced from 4px */
}

.filter-tag {
  display: flex;
  align-items: center;
  background-color: #D7ECFD;
  color: #080350;
  padding: 1px 4px; /* Reduced padding */
  border-radius: 3px; /* Slightly reduced */
  font-size: 0.7em;
  font-weight: bold;
}

.filter-tag svg {
  margin-left: 3px; /* Reduced from 4px */
  cursor: pointer;
  color: #080350;
}

.contractors-table-container {
  flex: 1;
  overflow-x: auto;
  max-width: 100%;
  border: 1px solid var(--table-border);
  border-radius: 4px; /* Reduced from 8px */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Reduced shadow */
  margin-top: 5px; /* Added small top margin */
  padding-top: 0;
}

.contractors-table {
  width: 100%;
  min-width: 1000px;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.85rem; /* Slightly reduced */
  line-height: 1.2;
}

.contractors-table th,
.contractors-table td {
  padding: 4px 6px; /* Further reduced padding */
  text-align: left;
  border-bottom: 1px solid var(--table-border);
  vertical-align: middle;
}

.contractors-table th {
  background: var(--table-header-gradient);
  color: white;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 1000;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 0.3px;
  cursor: pointer;
  padding-right: 20px; /* Add space for the sort icon */
  position: relative; /* For positioning the sort icon */
}

.contractors-table th:first-child {
  border-top-left-radius: 8px;
}

.contractors-table th:last-child {
  border-top-right-radius: 8px;
}

.contractors-table tbody tr:hover {
  background-color: var(--table-row-hover);
  transition: background-color 0.3s ease;
}

.contractors-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.contractors-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

/* Adjust column widths */
.contractors-table th:nth-child(1),
.contractors-table td:nth-child(1) {
  width: 18%; /* Company Name - unchanged */
  text-align: left;
  font-size: 0.9rem;
  font-weight: bold;
}

.contractors-table th:nth-child(2),
.contractors-table td:nth-child(2) {
  width: 16%; /* Full Name - slightly narrower */
}

.contractors-table th:nth-child(3),
.contractors-table td:nth-child(3) {
  width: 30%; /* Services - unchanged */
}

.contractors-table th:nth-child(4),
.contractors-table td:nth-child(4) {
  width: 18%; /* Email - slightly narrower */
}

.contractors-table th:nth-child(5),
.contractors-table td:nth-child(5),
.contractors-table th:nth-child(6),
.contractors-table td:nth-child(6) {
  width: 9%; /* Verified and Stripe Verified - slightly wider */
  text-align: center;
}

.contractors-table input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 10px;
  color: var(--light-blue);
  position: relative;
}

.icon-button:hover {
  color: var(--blue);
}

.icon-button:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 1001;
  pointer-events: none;
  width: max-content;
  max-width: 200px;
}

.icon-button:last-child:hover::after {
  left: auto;
  right: 0;
  transform: translateX(0);
}

.icon-button.cancel {
  color: #f44336;
}

.icon-button.cancel:hover {
  color: #d32f2f;
}

.icon-button.edit {
  color: var(--dark-blue);
}

.icon-button.edit:hover {
  color: var(--blue);
}

.icon-button.key {
  color: var(--yellow);
}

.icon-button.key:hover {
  color: #FFD700;
}

.array-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.array-icon {
  cursor: pointer;
  margin-left: 5px;
  color: #4CAF50;
}

.array-icon:hover {
  color: #45a049;
}

.edit-pane-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-pane {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  width: 80%;
  max-width: 800px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--dark-blue);
}

.edit-pane h3 {
  margin-bottom: 20px;
  color: var(--dark-blue);
}

.edit-pane-content {
  flex: 1;
  overflow-y: auto;
}

.field {
  margin-bottom: 15px;
}

.field label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: bold;
}

.field input,
.field select {
  width: calc(100% - 30px);
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.nested-field {
  margin-left: 20px;
  border-left: 2px solid #ddd;
  padding-left: 10px;
}

.nested-field h4 {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nested-field h4 svg {
  margin-right: 5px;
}

.button-group {
  margin-top: 20px;
  text-align: right;
}

.button-group button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: var(--light-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-group button:hover {
  background-color: var(--blue);
}

.field button {
  margin-top: 5px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.field button:hover {
  background-color: #45a049;
}

pre {
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.array-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.add-icon {
  cursor: pointer;
  color: #4CAF50;
  margin-left: 10px;
}

.add-icon:hover {
  color: #45a049;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 4px;
}

.search-bar input {
  flex-grow: 1;
  margin-left: 10px;
  padding: 5px;
  border: none;
  background-color: transparent;
}

.search-bar svg {
  color: #666;
}

.array-icon {
  cursor: pointer;
  margin-left: 5px;
  color: #4CAF50;
}

.array-icon:hover {
  color: #45a049;
}

.array-item input {
  flex-grow: 1;
  margin-right: 5px;
}

.expanded-row {
  background-color: #f9f9f9;
}

.conInfo-expanded {
  padding: 20px;
  border: 1px solid var(--blue);
  border-radius: 4px;
  margin: 10px 0;
  background-color: #f9f9f9;
}

.conInfo-expanded h4 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.2em;
  color: var(--dark-blue);
}

.conInfo-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.conInfo-full-width {
  width: 100%;
}

.conInfo-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.conInfo-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.conInfo-field label {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
}

.conInfo-field span,
.conInfo-field p {
  background-color: var(--very-light-blue);
  padding: 5px 10px;
  border-radius: 4px;
  min-height: 30px;
  display: flex;
  align-items: center;
  text-align: left;
}

.conInfo-field input,
.conInfo-field select,
.conInfo-field textarea {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: left;
}

.conInfo-field textarea {
  height: 100px;
  resize: vertical;
}

.conInfo-row .conInfo-field {
  flex: 1;
}

.conInfo-column {
  flex: 1;
}

.conInfo-services {
  margin-top: 20px;
}

.conInfo-services label {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  color: #555;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.service-item {
  display: flex;
  align-items: center;
  background-color: var(--very-light-blue);
  color: var(--dark-blue);
}

.service-item input[type="checkbox"] {
  margin-right: 5px;
}

.service-item label {
  font-weight: normal;
  color: #333;
}

/* Styles for when editing is enabled */
.editing .conInfo-field input,
.editing .conInfo-field select,
.editing .conInfo-field textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.editing .conInfo-field textarea {
  height: 100px;
  resize: vertical;
}

.editing .service-item input[type="checkbox"] {
  cursor: pointer;
}

.editing .service-item label {
  cursor: pointer;
}

/* Adjust the first column width */
.contractors-table th:first-child,
.contractors-table td:first-child {
  width: 40px;
  text-align: center;
}

.conInfo-edit {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.conInfo-field {
  display: flex;
  flex-direction: column;
}

.conInfo-field label {
  font-weight: bold;
  margin-bottom: 5px;
}

.conInfo-field input,
.conInfo-field select,
.conInfo-field textarea {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.conInfo-field textarea {
  height: 100px;
  resize: vertical;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
}

.service-item {
  display: flex;
  align-items: center;
}

.service-item input[type="checkbox"] {
  margin-right: 5px;
}

.conInfo-services {
  margin-top: 20px;
}

.conInfo-services label {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  color: #555;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.service-item {
  background-color: var(--very-light-blue);
  padding: 5px 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
}

.service-item span {
  margin-right: 5px;
}

.remove-service {
  cursor: pointer;
  color: #f44336;
  font-size: 0.8em;
}

.add-service {
  display: inline-flex;
  align-items: center;
  background-color: var(--yellow);
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  color: var(--dark-blue);
}

.add-service select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: none;
  font-size: 1em;
  color: #000000;
  font-style: italic;
  cursor: pointer;
  padding: 5px 25px 5px 5px;
  width: 100%;
  outline: none;
}

.add-service::after {
  content: '+';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 1.2em;
  color: var(--dark-blue);
  font-weight: bold;
}

.add-service-icon {
  color: #4CAF50;
  margin-right: 5px;
  font-size: 1.2em;
  vertical-align: middle;
}

.button-group {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.save-button,
.cancel-button,
.edit-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.save-button {
  background-color: var(--blue);
  color: white;
}

.cancel-button {
  background-color: var(--dark-blue);
  color: white;
}

.edit-button {
  background-color: var(--light-blue);
  color: white;
}

.editing .conInfo-field input,
.editing .conInfo-field select,
.editing .conInfo-field textarea {
  border: 1px solid var(--light-blue);
}

.read-only {
  background-color: #f0f0f0;
  cursor: default;
}

.read-only:focus {
  outline: none;
}

.popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--blue);
  color: white;
  padding: 15px;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
  transform: translateY(100%);
}

.popup.visible {
  transform: translateY(0);
}

.popup-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.popup p {
  margin: 0;
  font-size: 1.1em;
  font-weight: bold;
  flex-grow: 1;
}

.loading-spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid white;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.active-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.filter-tag {
  display: flex;
  align-items: center;
  background-color: var(--very-light-blue);
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
  color: var(--dark-blue);
}

.filter-tag svg {
  margin-left: 5px;
  cursor: pointer;
  color: #f44336;
}

.service-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.service-tag {
  background-color: var(--very-light-blue);
  padding: 1px 3px; /* Reduced padding */
  border-radius: 8px; /* Reduced from 12px */
  font-size: 0.65rem; /* Reduced from 0.7rem */
  white-space: nowrap;
  display: inline-block;
  color: var(--dark-blue);
  margin: 1px;
}

.bio-cell {
  white-space: normal;
  line-height: 1.2;
  max-height: 3.6em; /* Show up to 3 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.boolean-cell {
  text-align: center;
  padding: 4px 8px !important; /* Add horizontal padding */
}

.boolean-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Fill the entire cell width */
  height: 24px; /* Keep the current height */
  border-radius: 12px; /* Half of the height to create pill shape */
  margin: 0 auto;
}

.check-icon {
  color: #4CAF50;
  font-size: 1rem;
  background-color: rgba(76, 175, 80, 0.1);
}

.times-icon {
  color: #F44336;
  font-size: 1rem;
  background-color: rgba(244, 67, 54, 0.1);
}

.services-cell {
  width: 40%;
  max-width: 40%;
  white-space: normal;
}

.service-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 2px; /* Reduced from 4px */
  align-items: flex-start;
}

.service-tag {
  background-color: var(--very-light-blue);
  padding: 1px 3px; /* Reduced padding */
  border-radius: 8px; /* Reduced from 12px */
  font-size: 0.65rem; /* Reduced from 0.7rem */
  white-space: nowrap;
  display: inline-block;
  margin-bottom: 2px;
  color: var(--dark-blue);
}

.password-reset-button {
  background-color: var(--yellow);
  color: var(--dark-blue);
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.password-reset-button:hover {
  background-color: #FFD700;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: #333;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  background-color: white;
  border: 1px solid var(--blue);
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 150px;
}

.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 8px 12px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  color: #333;
}

.dropdown-menu button:hover {
  background-color: var(--very-light-blue);
}

.dropdown-menu button svg {
  margin-right: 8px;
}

.email-input-container {
  display: flex;
  align-items: center;
}

.email-input-container input {
  flex-grow: 1;
}

.password-reset-icon {
  color: var(--yellow);
  cursor: pointer;
  margin-left: 10px;
  font-size: 1.2em;
}

.password-reset-icon:hover {
  color: #FFD700;
}

/* Add subtle vertical lines between columns */
.contractors-table td:not(:last-child),
.contractors-table th:not(:last-child) {
  border-right: 1px solid rgba(224, 224, 224, 0.3);
}

/* Add a pointer cursor to sortable headers */
.contractors-table th {
  cursor: pointer;
}

/* Add sort icons */
.sort-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.8em;
}

/* Remove the existing sort indicator styles */
.contractors-table th::after {
  content: none;
}

.contractors-table th.sort-asc::after,
.contractors-table th.sort-desc::after {
  content: none;
}

/* Remove sort styling from Services column */
.contractors-table th:nth-child(3) {
  cursor: default;
}

.contractors-table th:nth-child(3) .sort-icon {
  display: none;
}

.export-button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  background: linear-gradient(to bottom, var(--light-blue), var(--blue));
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  transition: all 0.3s ease;
  height: 36px;
  margin-left: auto;
  white-space: nowrap;
}

.export-button:hover {
  background: linear-gradient(to bottom, var(--blue), var(--dark-blue));
}

.export-button svg {
  font-size: 1em;
  margin-right: 4px;
  color: white !important;
}

.table-header {
  display: flex;
  align-items: center; /* Align items vertically */
}

.sort-icon {
  margin-left: 5px;
  font-size: 0.8em;
}

/* ... (rest of the CSS remains unchanged) ... */
.map-report {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.map-report-header {
    background-color: #f0f2f5;
    padding: 1rem;
}

.header-controls {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.back-button,
.jump-to-select,
.overlay-button {
    height: 40px; /* Set a consistent height for all controls */
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box; /* Ensure padding is included in the height */
}

.back-button {
    background-color: #080350;
    color: #ffffff;
    border: none;
    text-decoration: none; /* Remove underline */
}

.jump-to-container {
    position: relative;
    width: 185px;
    height: 40px; /* Match the height of other controls */
}

.jump-to-select {
    background-color: #080350;
    color: #ffffff;
    border: 1px solid #ffffff;
    appearance: none;
    width: 100%;
    padding-right: 30px; /* Make room for the arrow */
    text-align: left; /* Align text to the left */
    text-align-last: left; /* Align the selected option text to the left */
}

.arrow-container {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay-button {
    background-color: #ffffff;
    color: #080350;
    border: 1px solid #080350;
}

.back-button:hover {
    background-color: #1e90ff; /* Light blue color for hover */
}

.jump-to-select:hover {
    background-color: #1e90ff; /* Light blue color for hover */
    color: #ffffff; /* Keep text white */
}

.overlay-button:hover {
    background-color: #1e90ff; /* Light blue color for hover */
    color: #ffffff;
}

.overlay-button.active {
    background-color: #080350;
    color: #ffffff;
}

.overlay-button.active:hover {
    background-color: #1e90ff; /* Use the same hover color for active buttons */
}

.map-container {
    flex: 1;
    position: relative;
    min-height: 0;
}

.mapboxgl-map {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.marker-popup {
    background-color: #080350;
    color: #ffffff;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    max-width: 200px;
}

.marker-popup h3 {
    margin: 0 0 5px 0;
    font-size: 16px;
}

.marker-popup p {
    margin: 0;
}

.error-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border: 2px solid #dc3545;
    border-radius: 4px;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.error-popup p {
    margin-bottom: 15px;
    color: #dc3545;
}

.error-popup button {
    background-color: #dc3545;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.error-popup button:hover {
    background-color: #c82333;
}

@media (max-width: 768px) {
    .header-controls {
        flex-direction: column;
        align-items: stretch;
    }

    .jump-to-select,
    .overlay-button {
        width: 100%;
    }
}
.homeowners {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.homeowners h2 {
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 4px;
}

.search-bar input {
  flex-grow: 1;
  margin-left: 10px;
  padding: 5px;
  border: none;
  background-color: transparent;
}

.search-bar svg {
  color: #666;
}

.homeowners-table-container {
  flex: 1;
  overflow-x: auto; /* Allow horizontal scrolling if needed */
  padding-right: 20px; /* Add some padding to the right */
}

.homeowners-table {
  width: 100%;
  min-width: 800px; /* Set a minimum width to ensure all columns are visible */
  border-collapse: collapse;
}

.homeowners-table th,
.homeowners-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.homeowners-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure this is lower than the tooltip's z-index */
}

.homeowners-table input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 10px;
  color: #1e90ff; /* Changed from #4CAF50 to blue */
  position: relative;
}

.icon-button:hover {
  color: #0056b3; /* Darker blue on hover */
}

.icon-button:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 1001; /* Increased z-index */
  pointer-events: none; /* Prevents the tooltip from interfering with clicks */
  width: max-content; /* Allow the tooltip to expand as needed */
  max-width: 200px; /* Set a maximum width for very long tooltips */
}

.icon-button.cancel {
  color: #f44336; /* Keep red for cancel */
}

.icon-button.cancel:hover {
  color: #d32f2f; /* Darker red on hover */
}

.icon-button.edit {
  color: #080350; /* Dark blue for pencil icon */
}

.icon-button.edit:hover {
  color: #0056b3; /* Lighter blue on hover */
}

.icon-button.key {
  color: #ffd700; /* Yellow for key icon */
}

.icon-button.key:hover {
  color: #ffc107; /* Darker yellow on hover */
}

/* Add this new rule */
.icon-button:last-child:hover::after {
  left: auto;
  right: 0;
  transform: translateX(0);
}
body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboard {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.dashboard__main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* Remove the padding-left property */
}

.dashboard__user-info {
  padding: 0.375rem 0.75rem; /* Reduced from 0.5rem 1rem */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  background-color: #080350;
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 52.5px; /* Reduced from 70px */
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard__internal-tools {
  color: #ffffff;
  font-size: 1.5rem; /* Reduced from 2rem */
  font-weight: 700;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis for overflow text */
}

.dashboard__beta-stamp {
  font-size: 0.6rem; /* Reduced from 0.8rem */
  font-weight: 900;
  color: #080350;
  background-color: #ffffff;
  padding: 1.5px 4.5px; /* Reduced from 2px 6px */
  margin-left: 7.5px; /* Reduced from 10px */
  border-radius: 4px;
  transform: rotate(-12deg);
  display: inline-block;
  text-transform: uppercase;
  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
  letter-spacing: 0px;
  position: relative;
  top: -5px;
}

.dashboard__beta-stamp::before,
.dashboard__beta-stamp::after,
.dashboard__beta-stamp span::before,
.dashboard__beta-stamp span::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border: 3px solid #ffffff;
}

.dashboard__beta-stamp::before {
  top: -1px;
  left: -3px;
  border-right: none;
  border-bottom: none;
}

.dashboard__beta-stamp::after {
  bottom: -1px;
  right: -3px;
  border-left: none;
  border-top: none;
}

.dashboard__beta-stamp span::before {
  top: -1px;
  right: -3px;
  border-left: none;
  border-bottom: none;
}

.dashboard__beta-stamp span::after {
  bottom: -1px;
  left: -3px;
  border-right: none;
  border-top: none;
}

.dashboard__welcome-message {
  flex-grow: 1;
  text-align: center;
  font-size: 1.2rem; /* Reduced from 1.6rem */
  font-weight: 600;
  color: #ffffff;
  margin: 0 0.9375rem; /* Reduced from 0 1.25rem */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis for overflow text */
}

.dashboard__logout-button {
  padding: 0.375rem 0.9375rem; /* Reduced from 0.5rem 1.25rem */
  font-size: 0.975rem; /* Reduced from 1.3rem */
  font-weight: 500;
  color: #fff;
  background-color: #dc3545;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap; /* Prevent button text from wrapping */
}

.dashboard__logout-button:hover {
  background-color: #c82333;
}

.dashboard__view-wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.dashboard__view-container {
  flex: 1;
  overflow-y: auto;
  padding: 0.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

/* Updated styles for homeowners, contractors, and reports */
.homeowners, .contractors, .reports {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.homeowners h2, .contractors h2, .reports h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.homeowners ul, .contractors ul, .reports ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  flex: 1;
}

.homeowners li, .contractors li, .reports li {
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  background-color: #f0f0f0;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.homeowners li:hover, .contractors li:hover, .reports li:hover {
  background-color: #e0e0e0;
}

/* Responsive grid layout for larger screens */
@media (min-width: 768px) {
  .homeowners ul, .contractors ul, .reports ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }

  .homeowners li, .contractors li, .reports li {
    margin-bottom: 0;
  }
}

/* Ensure that the content inside each view takes up full height */
.dashboard__view-container > div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Style adjustments for specific views */
.user-details {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-details .field {
  margin-bottom: 1rem;
}

.user-details input[type="text"],
.user-details input[type="number"] {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.user-details button {
  padding: 0.5rem 1rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-details button:hover {
  background-color: #45a049;
}

.user-details pre {
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-x: auto;
}

/* Remove unused styles */
.placeholder {
  display: none;
}

/* Add these styles at the end of the file */

.contractors-table {
  width: 100%;
  border-collapse: collapse;
}

.contractors-table th,
.contractors-table td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.contractors-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.edit-button,
.reset-password-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.edit-button:hover,
.reset-password-button:hover {
  background-color: #e0e0e0;
}

.edit-button svg,
.reset-password-button svg {
  font-size: 0.9rem;
}

/* Add this at the end of the file */
.dashboard {
  display: flex;
  transition: margin-left 0.3s ease;
}

.dashboard.sidebar-collapsed {
  margin-left: -120px; /* Adjust this value based on the difference between expanded and collapsed sidebar widths */
}